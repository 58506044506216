<template>
  <div id="exhibitionWorkDetail" v-if="data.workInfo != null">
    <div class="exhibitionWorkDetail-container">
      <div class="title">
        <span>{{ data.workInfo.name }}</span>
      </div>
      <div class="author-name">
        <span>{{ data.workInfo.author }}</span>
      </div>
      <div class="work-detail">
        <div class="work-item-detail-box">
          <div class="work-item-cate">
            <span style="vertical-align: middle">{{ typeList[data.workInfo.type - 1].name }}</span>
          </div>
          <div class="work-item-cate">
            <span style="vertical-align: middle">{{ cateList[data.workInfo.category - 1].name }}</span>
          </div>
        </div>
      </div>
      <div class="work-desc">
        <p v-html="data.workInfo.description">
        </p>
      </div>
      <div v-if="cate == 1" class="Image-box">
        <div v-for="(item, index) in data.workInfo.content" :key="index" class="imagItem">
          <el-image lazy :preview-src-list="data.workInfo.content" style="width: 100%; height: 100%" :src="item"
            :fit="'cover'" @contextmenu.prevent.stop="rightClick($event)">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
      <div v-else class="Image-box">
        <div class="videoBox">
          <video :src="data.workInfo.content[0]" style="height: 100%; width: 100%" controls="controls"
            controlsList="nodownload" @contextmenu.prevent.stop="rightClick($event)"></video>
        </div>
      </div>
      <div class="share-wrap">
        <div class="share-text">分享到</div>
        <div class="dash"></div>
        <div class="icon-wrap">
          <el-popover placement="top" :width="100" trigger="click" @show="showCode" style="min-width: auto">
            <template #reference>
              <div class="share-icon wechat-share-icon" @click="shareEvent('wechat')" :style="{
                backgroundImage: `url(${require(`../assets/img/share/wechat.png`)})`,
              }">
                <p class="share-platform">微信</p>
              </div>
            </template>
            <div id="qrcode" ref="qrcode" style="width: 100px"></div>
          </el-popover>
          <div class="share-icon-wrap" v-for="(item, index) in shareIcon" :key="index" @click="shareEvent(item)">
            <div class="share-icon" :class="{ 'copy-url': item === 'url' }" :style="{
              backgroundImage: `url(${require(`../assets/img/share/${item}.png`)})`,
            }"></div>
            <p class="share-platform" v-if="item === 'weibo'">微博</p>
            <p class="share-platform" v-if="item === 'QQ'">QQ</p>
            <p class="share-platform" v-if="item === 'qqSpace'">QQ空间</p>
          </div>
        </div>
      </div>
      <input v-model="currentUrl" id="copyValue" readonly style="opacity: 0" />
    </div>
  </div>
</template>

<script>
import { ref, reactive, nextTick } from "vue";
import QRCode from "qrcodejs2";
import { microBlog, qqSpace, QQ } from "../utils/share";
import { useRoute, useRouter } from "vue-router";
import { entryDetail } from '../utils/apis/exhibition';
import baseInfo from "../utils/config";


export default {
  setup() {
    const coverImage = ref(
      "https://cyc-test.oss-cn-shanghai.aliyuncs.com/design-competition/2021-04-23/16191407496701618967035552.jpg"
    );
    const route = useRoute();
    const router = useRouter();
    const shareIcon = reactive(["QQ", "weibo", "qqSpace"]);
    const currentUrl = ref(window.location.href);
    const cate = ref(route.query.cate);
    const qrcodes = ref(null);
    const data = reactive({
      workInfo: null,
    });
    const cateList = [
      {
        name: "平面类",
        id: 1
      },
      {
        name: "动画类",
        id: 2
      }
    ]
    const typeList = [
      {
        name: "特邀作品",
        id: 1
      },
      {
        name: "日韩作品",
        id: 2
      },
      {
        name: "大赛作品",
        id: 3
      }
    ]
    getDetail();
    document.οnmοusedοwn = function (event) {
      if (window.event && !event) {
        event = window.event;
      }

      var ref = event.target || event.srcElement;

      if (ref.tagName == "IMG") {
        if (event.button == 2 || event.button == 3) {
          alert("对不起,本网站不提供图片右键功能,谢谢您的理解和支持!");
          return false;
        }
      }
    };

    function qrcode() {
      console.log(baseInfo);
      if (qrcodes.value) {
        qrcodes.value.clear();
        qrcode();
      } else {
        let url =
          route.query.type === 2
            ? `
        ${baseInfo.MOBILE_URL}themeWorksImageWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.cate}&views=${route.query.views}`
            : `${baseInfo.MOBILE_URL}themeWorksImageWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.cate}&views=${route.query.views}`;
        qrcodes.value = new QRCode("qrcode", {
          width: 100,
          height: 100, // 高度
          text: url, // 二维码内容
          // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          // background: '#f0f'
          // foreground: '#ff0'
        });
      }
    }

    async function showCode() {
      await nextTick();
      qrcode();
    }

    /**
     * 分享
     */
    async function shareEvent(type) {
      console.log(type);
      if (type === "QQ") {
        let mobileurl =
          route.query.type === 2
            ? `
        ${baseInfo.MOBILE_URL}themeWorksImageWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.cate}&views=${route.query.views}`
            : `${baseInfo.MOBILE_URL}themeWorksImageWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.cate}&views=${route.query.views}`;
        let url = QQ({
          author: data.workInfo.author,
          worksname: data.workInfo.name,
          description: data.workInfo.description,
          url: mobileurl,
          pic: data.workInfo.content[0],
        });

        console.log(url);
        window.open(encodeURI(url));
      }
      if (type === "qqSpace") {
        let url = qqSpace({
          author: data.workInfo.author,
          worksname: data.workInfo.name,
          description: data.workInfo.description,
          url: location.href,
          pic: data.workInfo.content[0],
        });

        console.log(url);
        window.open(encodeURI(url));
      }
      //微博
      if (type === "weibo") {
        let url = microBlog({
          author: data.workInfo.author,
          worksname: data.workInfo.name,
          url: location.href,
          pic: data.workInfo.content[0],
        });
        window.open(encodeURI(url));
      }
    }
    async function getDetail() {
      let res = await entryDetail(route.query.id)
      if (res.data.code === 200) {
        data.workInfo = res.data.data;
        console.log(data.workInfo)
      }
    }

    function goBack() {
      if (route.query.type == 2) {
        router.push({ path: "/freeThemeWorks" });
      } else {
        router.push({ path: "/ThemeWorks" });
      }
    }

    function rightClick(event) {
      console.log(event);
    }

    function formatDate(date) {
      let d = new Date(date)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
    return {
      coverImage,
      shareIcon,
      shareEvent,
      currentUrl,
      route,
      cate,
      goBack,
      data,
      qrcode,
      showCode,
      rightClick,
      formatDate,
      typeList,
      cateList,
    };
  },
};
</script>

<style lang="less" scoped>
#exhibitionWorkDetail {
  .exhibitionWorkDetail-container {
    width: 1030px;
    margin: 0 auto;
    padding-top: 30px;
    overflow: hidden;
  }

  .search-box {
    margin: 0 0 25px 0;
    height: 30px;
    display: flex;
    line-height: 30px;
    border-radius: 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    width: 240px;
    padding-left: 15px;

    .icon-box {
      font-size: 22px;
      color: #dcdfe6;
    }

    .input-box {
      font-size: 0px;
      height: 28px;
      padding-left: 10px;
    }

    .input-box input {
      border: none;
      box-sizing: border-box;
      height: 100%;
      outline: none;
      color: #909399;
      width: 170px;
      background-color: #ffff;
    }
  }

  .bread-title {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }

  .list-name {
    float: left;
    margin-right: 25px;

    span:first-child {
      margin-right: 8px;
      margin-left: 5px;
    }

    .typename {
      font-weight: normal;
      cursor: pointer;
    }

    .typename:hover {
      color: #013589;
      margin-right: 8px;
    }

    span {
      font-weight: bold;
      color: #000;
    }
  }

  .title {
    color: #303133;
    font-weight: 500;
    font-size: 32px;
    margin-top: 45px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 50px;

    span:first-child {
      margin-right: 8px;
    }


  }

  .author-name {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px
  }

  .work-desc {
    line-height: 30px;
    color: #303133;
    margin-bottom: 39px;
    font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: justifyLeft;
    color: #333333;

    &>p {
      display: inline-block;
    }
  }

  .work-detail {
    margin-bottom: 32px;

    .work-item-detail-box {
      margin-top: 5px;
      height: 14px;
      position: relative;

      .work-item-cate,
      .work-item-createtime,
      .work-item-views {
        display: inline-block;
        color: #909399;
        font-size: 12px;
        margin-right: 11px;
      }

      .work-item-createtime {
        margin-right: 12px;
      }
    }

    .detail-right {
      font-size: 12px;
      float: right;
      width: 102px;
      display: flex;
      justify-content: space-around;
      line-height: 30px;

      .report {
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;

        &>img {
          width: 12px;
          height: 11px;
          margin-right: 5px;
        }
      }

      .view {
        display: flex;
        justify-content: space-around;
        align-items: center;

        &>img {
          width: 17px;
          height: 11px;
          margin-right: 6px;
        }
      }
    }
  }

  .Image-box {
    padding-bottom: 40px;
  }

  .imagItem {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 1030px;
    // min-width: 1200px;
    text-align: center;
  }

  .videoBox {
    height: 820px;
    margin-bottom: 40px;
    background-color: #303133;
  }

  .share-wrap {
    // float: right;
    display: flex;
    // width: 220px;
    margin-bottom: 60px;

    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;

    .dash {
      width: 440px;
      height: 0;
      border: 1px dashed #E5E5E5;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .share-text {
      text-align: center;
      font-size: 16px;
      color: #909399;
      line-height: 32px;
      margin-right: 20px;
    }

    .icon-wrap {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 296px;

      .share-platform {
        font-size: 16px;
        font-weight: 400;
        margin-top: 12px;
      }

      .share-icon-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .share-icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 51px;
        height: 51px;
        cursor: pointer;

      }

      .wechat-share-icon {
        height: 84px;
        background-size: 51px 50px;
        background-position: 0 0;
        position: relative;

        .share-platform {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 51px;
          transform: translateX(-50%);
          text-align: center;
        }
      }
    }
  }

  .report-form {
    ::v-deep .el-dialog {
      box-sizing: border-box;
      width: 614px !important;
      height: 882px;
      border-radius: 4px;
      background-color: #F7F7F7;
      padding-left: 27px;

      .title {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
      }

      .el-form-item__label {
        padding: 0 19px 0 0;
      }

      .avator-form-item {
        .el-form-item__label {
          line-height: 80px;
        }
      }

      .input-item {
        width: 300px;
        position: relative;
      }

      .el-textarea {
        width: 520px;

        .el-textarea__inner {
          width: 520px;
          height: 90px;
        }
      }

      .reason-wrap {
        width: 410px;


        .reason-item {
          width: 70px;
          float: left;
          height: 34px;
          line-height: 34px;
          color: rgba(0, 0, 0, .3);
          border: 1px solid #cccccc;
          text-align: center;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .reasonactive {
          border: 1px solid #325888;
        }
      }
    }

    .submit-report-btn {
      width: 152px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      bottom: 44px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #325888;
      border-radius: 4px;
      cursor: pointer;
      color: #fff;
      text-align: center;
    }

    .myfile2 {
      .upload-icon {
        width: 25px;
        height: 24px;
        position: absolute;
        top: 58px;
        left: 50%;
        transform: translateX(-50%);
      }

      .upload-type {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
        line-height: 20px;
        position: absolute;
        top: 94px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .upload-content {
      ::v-deep img {
        object-fit: cover;
      }
    }

    .upload-tip {
      margin: 8px 0;
      line-height: 14px;
      font-size: 14px;
      color: #c0c4cc;
      height: 14px;
    }
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 270px;
    height: 170px;
    line-height: 170px;
  }

  ::v-deep .el-upload--picture-card {
    width: 270px;
    height: 170px;
    line-height: 170px;
    position: relative;
    background-color: #fff;
  }


  ::v-deep .el-upload {
    position: relative;
    width: 260px;
    height: 170px;
  }

  ::v-deep .el-image__placeholder {
    height: 500px;
  }
}
</style>
<style>
.el-popover.el-popper {
  min-width: auto;

}
</style>